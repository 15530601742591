.layout {
    width: 100%;
    height: 100%;
    padding: 40px 60px;
    display: flex;
    gap: 20px;
    overflow: hidden;

    &_aside {
        flex: 1 1 100%;
        max-width: 400px;
    }

    &_content {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &_widgets {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            > article {
                flex: 1 1 150px;
                max-height: 100px;
            }
        }

        > section {
            flex: 1 1 auto;
        }
    }
}
