@font-face {
  font-family: 'ats-icons';
  src:  url('ats-icons.eot?cook2');
  src:  url('ats-icons.eot?cook2#iefix') format('embedded-opentype'),
    url('ats-icons.ttf?cook2') format('truetype'),
    url('ats-icons.woff?cook2') format('woff'),
    url('ats-icons.svg?cook2#ats-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ats-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-exclamation:before {
  content: "\e93e";
}
.icon-info_outline:before {
  content: "\e93f";
}
.icon-update:before {
  content: "\e93d";
}
.icon-pin_drop:before {
  content: "\e93b";
}
.icon-print:before {
  content: "\e93c";
}
.icon-menu:before {
  content: "\e937";
}
.icon-remove:before {
  content: "\e938";
}
.icon-shopping_cart:before {
  content: "\e939";
}
.icon-straight:before {
  content: "\e93a";
}
.icon-amex:before {
  content: "\e932";
}
.icon-discover:before {
  content: "\e933";
}
.icon-generic_card:before {
  content: "\e934";
}
.icon-mastercard:before {
  content: "\e935";
}
.icon-visa:before {
  content: "\e936";
}
.icon-pending_actions:before {
  content: "\e930";
}
.icon-check:before {
  content: "\e92a";
}
.icon-check_circle_fill:before {
  content: "\e92b";
}
.icon-login:before {
  content: "\e92c";
}
.icon-logout:before {
  content: "\e92d";
}
.icon-menu_close:before {
  content: "\e92e";
}
.icon-radio_button_checked:before {
  content: "\e92f";
}
.icon-close:before {
  content: "\e928";
}
.icon-add:before {
  content: "\e929";
}
.icon-redo:before {
  content: "\e921";
}
.icon-undo:before {
  content: "\e922";
}
.icon-cancel:before {
  content: "\e923";
}
.icon-check_circle:before {
  content: "\e924";
}
.icon-density_medium:before {
  content: "\e925";
}
.icon-density_small:before {
  content: "\e926";
}
.icon-pending:before {
  content: "\e927";
}
.icon-error:before {
  content: "\e91d";
}
.icon-info:before {
  content: "\e91e";
}
.icon-verified:before {
  content: "\e91f";
}
.icon-warning:before {
  content: "\e920";
}
.icon-account_circle:before {
  content: "\e900";
}
.icon-arrow_back:before {
  content: "\e901";
}
.icon-attach_file:before {
  content: "\e902";
}
.icon-attach_money:before {
  content: "\e903";
}
.icon-autorenew:before {
  content: "\e904";
}
.icon-chevron_left:before {
  content: "\e905";
}
.icon-chevron_right:before {
  content: "\e906";
}
.icon-content_copy:before {
  content: "\e907";
}
.icon-credit_card:before {
  content: "\e908";
}
.icon-dashboard:before {
  content: "\e909";
}
.icon-download:before {
  content: "\e90a";
}
.icon-edit:before {
  content: "\e90b";
}
.icon-edit_note:before {
  content: "\e90c";
}
.icon-expand_less:before {
  content: "\e90d";
}
.icon-expand_more:before {
  content: "\e90e";
}
.icon-filter_alt:before {
  content: "\e90f";
}
.icon-folder:before {
  content: "\e910";
}
.icon-group:before {
  content: "\e911";
}
.icon-link:before {
  content: "\e912";
}
.icon-menu_book:before {
  content: "\e913";
}
.icon-menu_open:before {
  content: "\e914";
}
.icon-payments:before {
  content: "\e915";
}
.icon-school:before {
  content: "\e916";
}
.icon-search:before {
  content: "\e917";
}
.icon-sell:before {
  content: "\e918";
}
.icon-settings:before {
  content: "\e919";
}
.icon-summarize:before {
  content: "\e91a";
}
.icon-upload:before {
  content: "\e91b";
}
.icon-upload_file:before {
  content: "\e91c";
}
.icon-account_balance_wallet:before {
  content: "\e931";
}
