:root {
    //Sizes
    --border-radius: 4px;

    //Colors
    --back-aside: #1c1e30;

    //basics
    --color-black: #000000;
    --color-white: #ffffff;

    //Purple & Variations
    --color-purple: #2b2e4a;
    --color-light-purple: #808298;

    //Blue & Variations
    --color-blue: #003c74;
    --color-light-blue: #6692bc;
    --color-sky-blue: #d9e4ee;
    --color-lighter-sky-blue: #f2f6f9;

    //Green & Variations
    --color-green: #039b54;
    --color-light-green: #daf6e9;

    //Red & Variations
    --color-red: #e06658;
    --color-light-red: #f7d8d5;

    //Orange & Variations
    --color-orange: #ff9a28;
    --color-light-orange: #ffe5c9;

    //Grey & Variations
    --color-gray: #c1c6c8;
    --color-light-gray: #dfe0e4;
}
