.layout {
    width: 100%;
    height: 100%;
    padding: 20px 60px;
    display: flex;
    gap: 20px;

    &_aside {
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        > div {
            min-height: 150px;
        }
    }

    &_main {
        width: 100%;
    }


    &_headingDate {
        width: 100%;
        display: flex;
        margin-top: 10px;

        &_close {
            height: 100%;
        }
    }

    .iconEditComment {
        font-size: 15px;
    }
}

.infoWrapper {
    display: flex;
    justify-content: space-between;
}
