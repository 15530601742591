@import "../../../../stylesheets/utilities";

.ats_aside_menu_item {
    display: block;
    cursor: pointer;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    color: #fff;
    background-color: transparent;
    font-size: 14px;
    font-weight: font-weight(regular);
    border-radius: 4px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    transition: background-color 0.3s;

    &.active {
        background-color: rgba($color: #fff, $alpha: 0.3);
        font-weight: font-weight(bold);
    }

    &:hover {
        background-color: rgba($color: #fff, $alpha: 0.1);
    }

    > i {
        font-size: 20px;
    }

    > span {
        width: var(--span-menu-item, 100%);
        margin-left: 10px;
        opacity: var(--span-menu-item, 1);
        transition: width 0.3s, opacity 0.2s;
        overflow: hidden;
    }

    &.collapse {
        width: auto;
        border: none;
    }
}
