@import "../../../stylesheets/utilities";

.progressBar {
    padding: 15px;
    background-color: var(--color-light-gray);
    border: solid 1px var(--color-purple);
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 8px rgb(0 74 143 / 10%);

    &_title {
        font-size: 24px;
        font-weight: font-weight(bold);
        text-transform: uppercase;
        color: var(--color-purple);
        text-align: center;
    }

    &_info {
        font-size: 16px;
        font-weight: font-weight(medium);
        text-align: center;
    }

    &_tracker {
        width: 100%;
        border: solid 1px var(--color-purple);
        border-radius: var(--border-radius);
        background-color: #fff;
        overflow: hidden;
        margin: 10px 0;
    }

    &_track {
        width: var(--percentage);
        height: 11px;
        transition: width 1s ease-in-out;
        border: solid 1px #fff;
        border-radius: var(--border-radius);
        background-color: var(--color-purple);
    }
}
