.layout {
    width: 100%;
    height: 100%;
    padding: 20px 60px;
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;

    &_aside {
        flex: 1 1 auto;
        width: 100%;
        min-width: 300px;
        max-width: 400px;
    }

    &_content {
        width: 100%;
    }

    >div {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}