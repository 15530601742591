.layout {
    width: 100%;
    height: 100%;
    padding: 20px 60px;
    display: flex;
    gap: 20px;

    &_aside {
        flex: 1 1 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &_main {
        width: 100%;
        height: 100%;
    }
}
