.layout {
    width: 100%;
    height: 100%;
    padding: 20px 60px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 2fr 3fr 1fr 6fr;
    gap: 15px;

    > div {
        &:nth-child(1) {
            grid-area: 1/1/2/4;
        }

        &:nth-child(2) {
            grid-area: 2/1/3/4;
        }

        &:nth-child(3) {
            grid-area: 3/1/5/4;
        }

        &:nth-child(4) {
            grid-area: 1/4/4/13;
        }

        &:nth-child(5) {
            grid-area: 4/4/5/13;
        }
    }

    .iconEditComment {
        font-size: 15px;
    }
}

.token {
    background-color: var(--color-sky-blue);
    color: var(--color-blue);
    border: solid 1px var(--color-blue);
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 8px rgba(0, 74, 143, 0.1);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &_info {
        width: 100%;
        display: flex;
        align-items: center;

        > span {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            margin-right: 5px;
        }

        > i {
            font-size: 20px;
            padding: 4px 4px;
            cursor: pointer;
            transition: background-color 0.3s;
            position: relative;
            border-radius: var(--border-radius);

            &:hover {
                --mintooltip: 1;
                background-color: rgba($color: #000000, $alpha: 0.05);
            }
            &::after {
                content: attr(title);
                position: absolute;
                width: max-content;
                position: absolute;
                top: -25px;
                left: -50%;
                font-size: 12px;
                font-weight: 500;
                font-family: "Noto Sans", sans-serif;
                color: #fff;
                background-color: var(--color-blue);
                padding: 5px;
                border-radius: var(--border-radius);
                opacity: var(--mintooltip, 0);
                pointer-events: none;
            }
        }
    }

    &_code {
        font-size: 26px;
        font-weight: 700;
        line-break: anywhere;
    }
}
