@import "../../../stylesheets/utilities";

.badge {
    position: relative;
    padding: 3px 8px;
    margin: 0 5px;
    border-radius: var(--border-radius);
    background-color: var(--card-bg, var(--color-sky-blue));
    color: var(--card-color, var(--color-blue));
    font-size: 12px;
    font-weight: font-weight(bold);
    text-transform: uppercase;

    &::before {
        content: "•";
        margin-right: 5px;
    }
}
