.layout {
    width: 100%;
    height: 100%;
    padding: 20px 60px;
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 20px;

    &_area {
        height: 100%;
    }

    &_headingDate {
        width: 100%;
        display: flex;

        &_close {
            height: 100%;
        }
    }

    .iconEditComment {
        font-size: 15px;
    }
}
