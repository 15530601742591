.steps {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;
    padding: 0 10px;
    list-style-type: none;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        z-index: 1;
        width: calc(100% - 30px);
        height: 3px;
        background-color: #5a5a5a;
    }

    > li {
        z-index: 5;
        width: var(--steps-size, 30px);
        height: var(--steps-size, 30px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-radius: 50%;
        background-color: var(--steps-bg, var(--color-blue));
        color: #fff;
        padding: 5px;
        position: relative;

        &.active {
            --steps-text: 700;
            --steps-check: none;
            --steps-circle: block;
            --steps-circle-border: #fff;

            ~ li {
                --steps-bg: transparent;
                --steps-check: none;
                --steps-circle: block;
            }
        }

        > i {
            display: var(--steps-check);
            font-size: 20px;
        }

        &::before {
            display: var(--steps-circle, none);
            content: "";
            width: 16px;
            min-height: 16px;
            border: solid 2px var(--steps-circle-border, #5a5a5a);
            border-radius: 50%;
            background-color: #fff;
            margin: 2px 0 3px;
        }

        &::after {
            content: attr(title);
            font-size: 14px;
            color: var(--color-blue);
            font-weight: var(--steps-text, 400);
            margin-top: 7px;
        }
    }
}
