.layout {
    width: 100%;
    height: 100%;
    padding: 20px 60px;
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;

    &_aside {
        flex: 1 1 auto;
        width: 100%;
        min-width: 300px;
        max-width: 400px;
    }

    &_content {
        width: 100%;
    }

    > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}

.resume {
    padding: 15px;
    color: var(--card-color);
    background-color: var(--card-bg);
    border: solid 1px var(--card-border-color);
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    box-shadow: 0px 4px 8px rgb(0 74 143 / 10%);

    &_column {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: var(--resume_column_align, flex-end);
        padding: 5px;

        &:nth-child(2) {
            --resume_column_align: flex-start;
            --resume_size: 16px;
            --resume_weight: 700;

            > p:nth-last-child(1) {
                --resume_size: 22px;
                --resume_weight: 800;
            }
        }

        > p {
            display: flex;
            align-items: center;
            height: 36px;
            font-size: var(--resume_size, 14px);
            font-weight: var(--resume_weight, 500);
            padding: 5px 0;
        }
    }
}
