.layout {
    width: 100%;
    height: 100%;
    padding: 20px 60px;
    display: flex;
    gap: 20px;

    &_aside {
        flex: 1 1 100%;
        max-width: 300px;
    }

    &_main {
        flex: 1 1 100%;
    }

    .downloadLink:hover {
        cursor: pointer;
    }

    .checkboxLabel {
        input {
            vertical-align: middle;
            margin-right: 5px;
        }

        span {
            vertical-align: middle;
        }
    }
}

.list_menu {
    width: 100%;
    padding: 0;
}