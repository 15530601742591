.ats_header {
    width: 100%;
    min-height: 120px;
    background-color: var(--color-blue);
    padding: 20px 60px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;

    &_area {
        flex: 1 1 auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;

        &:nth-child(1) {
            flex-direction: column;
        }
    }

    &_back {
        width: max-content;
        padding: 0;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        > i {
            margin-right: 5px;
        }
    }

    &_wrapper_title{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &_title {
            font-size: 24px;
            font-weight: 700;
            color: #fff;
            padding-right: 20px;
        }
    }
    
}
