.wrapper {
    position: relative;

    &_input {
        position: relative;

    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
        filter: grayscale(0.5);
    }

}