.datepicker {
    --spectrum-textfield-height: 35px;
    --spectrum-global-dimension-size-400: 35px !important;
    --spectrum-dropdown-height: 35px;
    --spectrum-fieldbutton-border-color: #003c74;
    --spectrum-textfield-border-color: #003c74;

    > div {
        width: 100%;
        height: 100%;
        background-color: transparent;

        > div {
            width: 100% !important;
            outline: var(--outline-alert);
        }
    }
}
