@import "../../stylesheets/utilities";
@import "./Sidebar/Sidebar.module.scss";

.ats_container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: row nowrap;
}

.ats_content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
}

.ats_main {
    width: 100%;
    height: 100%;
    overflow: auto;
    //padding: 40px 60px;

    &.full_width {
        padding: 0;
    }
}

.ats_layoutSpinnerContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    z-index: 10;
}