@import "../../../../stylesheets/variables";

.approved {
    color: var(--color-green);
}

.pending {
    color: var(--color-orange);
}

.rejected {
    color: var(--color-red);
}

.refunded {
    color: var(--color-red);
}

.dot {
    &::before {
        content: "\2022";
        margin-right: 5px;
    }
}

.boldUppercase {
    text-transform: uppercase;
    font-weight: bold;
}

.iconPayment {
    font-size: 19px;
    vertical-align: text-top;
    margin-right: 3px;
    &.paid {
        color: var(--color-green);
    }

    &.pending {
        color: var(--color-orange);
    }

    &.refunded {
        color: var(--color-red);
    }
}

.badgePadding {
    padding: 0 !important;
    margin: 0;
}
