*,
*::before,
*::after {
    box-sizing: border-box;
}
@-ms-viewport {
    width: device-width;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

[tabindex="-1"]:focus {
    outline: none !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    margin: 0;
}
a {
    -webkit-text-decoration-skip: objects;
    background-color: transparent;
    text-decoration: none;
    outline: 0;
}

b,
strong {
    font-weight: bolder;
}

ol,
ul,
dl {
    margin: 0;
    list-style-type: none;
}

pre {
    font-family: monospace, monospace;
    font-size: 1em;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
}
address {
    font-style: normal;
    line-height: inherit;
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    vertical-align: middle;
    border-style: none;
}
svg:not(:root) {
    overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

textarea {
    overflow: auto;
    resize: vertical;
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

table {
    border-collapse: collapse;
}
caption {
    padding: 0.75rem 0;
    caption-side: bottom;
}
th {
    text-align: inherit;
}

label {
    display: inline-block;
}

button {
    border-radius: 0;
    outline: none;
}

fieldset {
    min-width: 0;
    padding: 0.5rem;
    margin: 0;
    border: 0;
}

legend {
    color: inherit;
    display: block;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

[type="date"],
[type="time"],
[type="datetime-local"],
[type="month"] {
    -webkit-appearance: listbox;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

details {
    display: block;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}
