//TO EXPORT COURSECARD
.courseCard {
    width: 100%;
    border: solid 1px var(--color-blue);
    border-radius: var(--border-radius);
    display: flex;
    margin-bottom: 20px;
    position: relative;

    &_stats {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--color-sky-blue);
        border-right: solid 1px var(--color-blue);
        border-radius: 4px 0 0 4px;
        padding: 15px;
        color: var(--color-blue);

        &_pending {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;

            > strong {
                width: min-content;
                font-size: 26px;
                font-weight: 700;
                margin-right: 10px;
            }

            > span {
                width: min-content;
                font-size: 16px;
                font-weight: 500;
            }
        }

        &_info {
            text-align: center;
        }
    }

    &_info {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15px, 1fr));
        font-size: 16px;
        padding: 10px;

        > div {
            padding: 10px;
        }
        p {
            color: #000000;
        }
    }
    &_viewCourse {
        color: #000000;
        display: flex;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        > i {
            height: fit-content;
            transform: rotate(180deg);
            font-size: 16px;
            font-weight: 600;
        }
    }

    &:hover {
        .courseCard_viewCourse {
            color: var(--color-light-blue);
        }
    }

    &_tooltip {
        --tooltip-t: 25px;
        --tooltip-r: 0;
        --tooltip-arrow-t: -5px;
        --tooltip-arrow-r: 3px;
        position: absolute;
        font-size: 20px;
        right: -10px;
        top: -10px;
        color: var(--icon-color);
        border-radius: 100%;
        background-color: #fff;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            width: 30px;
            height: 30px;
            top: -6px;
            right: -5px;
            border-radius: 100%;
        }
    }
}
