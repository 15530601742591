@import "../../../stylesheets/utilities";

.status {
    &_container {
        --request-mw: 850px;
        width: 100%;
        max-width: var(--request-mw, 100%);
        margin: 0 auto;

        &.collapsed {
            --status-rotate: 0;
            --cardstatus-dir: row;
            --cardstatus-mt: 0;
            --cardstatus-align: center;
            --cardstatus-show: none;
            --cardstatus-icon: 30px;
        }
    }

    &_title {
        width: 100%;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: font-weight(bold);
        display: flex;
        align-items: center;
        padding: 10px;
        color: var(--request-color, #000);

        > button {
            margin-left: auto;
            font-size: 26px;
            transform: rotate(var(--status-rotate, 180deg)) translateY(3px);
            transition: transform 0.3s;
        }
    }

    &_content {
        width: 100%;
        display: flex;
        padding-bottom: 20px;
    }
}
