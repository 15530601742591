.wrapper {
    &_input {
        position: relative;
    }
}
.arrows {
    width: 17px;
    height: 33px;
    position: absolute;
    right: 1px;
    top: 1px;
    border-radius: 0 4px 4px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > button {
        width: 100%;
        height: 50%;
        padding: 0;
        transition: background-color 0.3s;

        &:hover {
            background-color: var(--color-lighter-sky-blue);
        }

        &:disabled {
            background-color: transparent;
            pointer-events: none;
        }
    }
}
