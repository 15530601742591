.spinner {
    &_container {
        position: relative;
        z-index: 700;
        width: 100%;
        height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &_wheel {
        width: clamp(20px, 4vw, 50px);
        height: clamp(20px, 4vw, 50px);
        border: solid 4px rgba($color: #7a7a7a, $alpha: 0.15);
        border-top-color: #003c74;
        border-radius: 50%;
        animation: wheel 0.6s infinite ease-in-out;
    }

    &_title {
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        padding: 10px 0;
        margin-top: 20px;
    }
}

@keyframes wheel {
    to {
        transform: rotate(360deg);
    }
}
