.layout {
    --request-color: #fff;
    width: 100%;
    height: 100%;
    padding: 30px 60px 20px;
    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 120px;
        background-color: var(--color-blue);
    }

    &_top {
        width: 100%;
        z-index: 5;
    }

    &_content {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 15px;
        overflow: auto;

        &_aside {
            min-width: 240px;
            max-width: 300px;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        &_main {
            flex: 1 1 auto;
        }
    }
}
