.modal {
    &_wrapper {
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;

        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 900;

        background: rgba(242, 246, 249, 0.8);
        backdrop-filter: blur(20px);
    }

    &_container {
        $modal-width: 400px;
        $modal-height: 500px;
        $min: 200px;
        position: relative;

        padding: 30px;
        max-width: $modal-width;
        max-height: $modal-height;
        min-width: $min;
        min-height: $min;
        border: 1px solid var(--color-light-gray);
        border-radius: var(--border-radius);
        background-color: var(--color-white);
        box-shadow: 0px 4px 8px rgba(0, 74, 143, 0.1);
    }

    &_closeBtn {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
    }

    &_content {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
    }

    &_title {
        max-width: 250px;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
    }

    &_icontitle {
        display: flex;
        align-items: center;
        font-size: 26px;
        font-weight: 400;
        margin-bottom: 20px;

        > i {
            font-size: 27px;
            margin-right: 10px;
            color: var(--icon-color);
        }
    }

    &_subtitle {
        width: 100%;
        font-size: 18px;
        text-align: center;
    }

    &_textbox {
        width: 100%;
        padding-bottom: 10px;
        p + p {
            margin-top: 5px;
        }
    }

    &_comment {
        width: 100%;
        padding-bottom: 10px;

        > label {
            font-size: 14px;
            font-weight: 700;
            color: #6692bc;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        textarea {
            min-height: 100px;
        }
    }

    &_btn_box {
        width: 100%;
        display: flex;
        justify-content: var(--modal-btn-box-align, flex-end);
        padding-top: 10px;

        &.centered {
            --modal-btn-box-align: center;
        }

        &.left {
            --modal-btn-box-align: flex-start;
        }
    }
}
