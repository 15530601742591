@import "../../../stylesheets/utilities";

.widget {
    padding: 20px 25px;
    background-color: var(--card-bg);
    border: solid 1px var(--card-border-color);
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 8px rgba(0, 74, 143, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &_number {
        font-size: 26px;
        font-weight: font-weight(bold);
        color: var(--card-color);
    }

    &_text {
        font-size: 16px;
        font-weight: font-weight(regular);
        color: var(--card-color);
    }

    &_tooltip {
        --tooltip-t: 25px;
        --tooltip-r: 0;
        --tooltip-arrow-t: -5px;
        --tooltip-arrow-r: 3px;
        position: absolute !important;
        font-size: 20px;
        right: 5px;
        top: 5px;
        cursor: pointer;
        background-color: transparent !important;

        &::after {
            content: "";
            position: absolute;
            width: 30px;
            height: 30px;
            top: -6px;
            right: -5px;
            border-radius: 100%;
        }
    }
}
