.btn {
    display: flex;
    align-items: center;
    width: max-content;
    min-height: 35px;
    padding: 7px 10px;
    font-weight: 600;
    white-space: nowrap;
    color: var(--btn-color, --color-light-blue);
    border-radius: var(--btn-radius, 4px);
    border-width: 2px;
    border-style: var(--btn-border, solid);
    border-color: var(--btn-border-color, var(--btn-color, --color-light-blue));
    background-color: var(--btn-bg, #fff);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border 0.3s;

    &:hover {
        --btn-bg: #{darken(#fff, 7)};
    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        filter: brightness(0.7);
    }

    &.form {
        --btn-bg: #d9e4ee;
        --btn-color: #003c74;

        &:hover {
            --btn-bg: #{lighten(#d9e4ee, 5%)};
        }
    }

    &.positive {
        --btn-bg: #003c74;
        --btn-color: #fff;
        --btn-border: none;

        &:hover {
            --btn-bg: #{lighten(#003c74, 10)};
        }
    }

    &.outpositive {
        --btn-bg: transparent;
        --btn-color: #003c74;

        &:hover {
            --btn-bg: #003c74;
            --btn-color: #fff;
            --btn-border-color: #003c74;
        }
    }

    &.outthin {
        --btn-bg: transparent;
        --btn-color: #003c74;
        border: solid 1px #003c74;

        &:hover {
            --btn-bg: #003c74;
            --btn-color: #fff;
            --btn-border-color: #003c74;
        }
    }

    &.negative {
        --btn-color: #003c74;
        --btn-border-color: #fff;

        &:hover {
            --btn-bg: #d9e4ee;
            --btn-color: #{lighten(#003c74, 5)};
        }
    }

    &.outnegative {
        --btn-bg: transparent;
        --btn-color: #fff;

        &:hover {
            --btn-bg: #{darken(#fff, 10)};
            --btn-color: #003c74;
            --btn-border-color: #fff;
        }
    }

    &.success {
        --btn-bg: #039b54;
        --btn-color: #fff;
        --btn-border: none;

        &:hover {
            --btn-bg: #{darken(#039b54, 10)};
        }
    }

    &.outsuccess {
        --btn-bg: transparent;
        --btn-color: #039b54;

        &:hover {
            --btn-bg: #039b54;
            --btn-color: #fff;
            --btn-border-color: #039b54;
        }
    }

    &.danger {
        --btn-bg: #e06658;
        --btn-color: #fff;
        --btn-border: none;

        &:hover {
            --btn-bg: #{darken(#e06658, 10)};
        }
    }

    &.outdanger {
        --btn-bg: transparent;
        --btn-color: #e06658;

        &:hover {
            --btn-bg: #e06658;
            --btn-color: #fff;
            --btn-border-color: #e06658;
        }
    }

    &.list {
        --btn-bg: #fff;
        width: 100%;
        padding: 20px;
        border: solid 2px transparent;

        &:hover {
            --btn-bg: #{darken(#fff, 3)};
        }

        > i {
            font-size: 25px;
            --icon-color: #039b54;
            margin-right: 10px;
        }

        &.active {
            border: solid 2px #003c74;
            --btn-color: #003c74;
            --btn-bg: var(--color-sky-blue);

            > i {
                --icon-color: #003c74 !important;
            }
        }
    }

    &.card {
        --btn-bg: #dfe0e4;
        --btn-color: #2b2e4a;
        --btn-ml: 0;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 8px rgba(0, 74, 143, 0.1);

        > i {
            font-size: 30px;
            margin-right: 10px;
        }

        &:hover {
            --btn-bg: darken(#dfe0e4, 10);
        }
    }

    + .btn {
        margin-left: var(--btn-ml, 20px);
    }

    &.warning {
        --btn-bg: #f79b42;
        --btn-color: #fff;
        --btn-border: none;

        &:hover {
            --btn-bg: #{darken(#f79b42, 10)};
        }
    }

    &.outwarning {
        --btn-bg: transparent;
        --btn-color: #f79b42;

        &:hover {
            --btn-bg: #f79b42;
            --btn-color: #fff;
            --btn-border-color: #f79b42;
        }
    }
}

.loading {
    position: relative;
    pointer-events: none;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        cursor: progress;
        animation: btnload 1.8s forwards infinite linear;
        background: linear-gradient(to right, #79797900 8%, #ffffff94 38%, #79797900 54%);
        background-size: 1000px 640px;
    }
}
@keyframes btnload {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}
