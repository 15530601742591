.simpleRadioButton {
    width: 100%;
    display: flex;
    align-items: center;

    > input {
        width: 20px;
    }

    > span {
        margin: 0;
        padding: 0 5px;
    }

    + * {
        margin-left: var(--items-pad);
    }
}
