.cardNumberWrapper {
    > i {
        left: inherit !important;
        right: 3px;
        top: 44px !important;
        font-size: 30px !important;
    }

    .cardNumber {
        width: 100%;
        padding: 5px 45px 5px 10px;
    }
}
