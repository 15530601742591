//RADIO BUTTON
.radioButton {
    display: block;
    position: relative;
    cursor: pointer;

    > input {
        width: 15px;
        position: absolute;
        top: -15px;
        left: -5px;

        &:checked + button {
            --radioButton-bg: var(--color-blue);
            --radioButton-color: #fff;
        }
    }

    > button {
        min-width: max-content;
        width: 100%;
        height: 35px;
        border: solid 1px var(--color-blue);
        border-radius: var(--border-radius);
        background-color: var(--radioButton-bg, var(--color-sky-blue));
        color: var(--radioButton-color, var(--color-blue));
        font-size: 16px;
        font-weight: 500;
        padding: 5px 10px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        > i {
            font-size: 22px;
            margin-right: 5px;
        }
    }

    + label {
        margin-left: 15px;
    }
}
