.accordion {
    width: 100%;
    max-width: 400px;
    list-style: none;
    margin: 0 auto 20px;
    padding: 0;
    border-top: solid 1px var(--color-sky-blue);
    border-bottom: solid 1px var(--color-sky-blue);
}

.buttonsWrapper {
    display: flex;
    justify-content: flex-end;
}
