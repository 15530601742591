.cvvWrapper {
    > i {
        left: inherit !important;
        left: 5px !important;
        top: 44px !important;
        font-size: 30px !important;
    }
    .cvv {
        width: 100%;
        padding: 5px 10px 5px 40px;
        text-align: right;
    }
}
