.customSelect {
    --item-padding: 7px 20px 7px 10px;
    position: relative;

    > button {
        width: 100%;
        height: 35px;
        padding: var(--item-padding, 7px 10px);
        background-color: #fff;
        border: solid 1px #003c74;
        border-radius: 4px;
        text-align: left;
        position: relative;

        &.toggled {
            --rotate_arrow: -180deg;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
            filter: grayscale(0.5);
        }

        > span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        > i {
            position: absolute;
            top: 7px;
            right: 3px;
            font-size: 20px;
            transition: transform 0.3s;
            transform: rotate(var(--rotate_arrow, 0));
        }
    }

    > ul {
        position: absolute;
        z-index: 450;
        width: 100%;
        min-width: min-content;
        background-color: #fff;
        padding: 0;
        border-radius: 0 0 var(--border-radius);
        box-shadow: 0px 3px 5px rgba($color: #000000, $alpha: 0.1);

        li {
            &:hover {
                background-color: var(--color-lighter-sky-blue);
            }

            button {
                width: 100%;
                padding: 7px 15px;
                text-align: left;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: wrap;
            }
        }

        &.scroll {
            max-height: 200px;
            overflow-y: auto;
        }
    }
}
