@import "../../../stylesheets/utilities";

.forgotPassword {
    &_main {
        width: 100%;
        height: 100vh;
        background-color: var(--color-lighter-sky-blue);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_box {
        width: 100%;
        max-width: 500px;
        background-color: #fff;
        border: solid 1px var(--color-sky-blue);
        border-radius: 4px;
        box-shadow: 0 4px 4px rgba($color: #000000, $alpha: 0.25);

        &_header {
            width: 100%;
            display: flex;
            border-bottom: solid 1px var(--color-sky-blue);
        }

        &_identity {
            padding: 22px 25px;
            border-right: solid 1px var(--color-sky-blue);

            > img {
                width: 100%;
                max-width: 40px;
            }
        }

        &_heading {
            width: 100%;
            padding: 10px 20px;
            display: flex;
            align-items: center;

            > i {
                font-size: 30px;
                margin-right: 10px;
                color: var(--icon-color);
            }
        }

        &_title {
            font-size: 26px;
            font-weight: font-weight(medium);
        }

        &_content {
            width: 100%;
            padding: 40px 80px;
        }
    }

    &_form {
        width: 100%;
    }

    &_btn_group {
        width: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: flex-end;
    }

    &_link {
        display: block;
        padding: 5px 0;
        margin-top: 10px;
        font-size: 14px;
        color: var(--color-blue);

        &:hover {
            text-decoration: underline;
        }
    }

    &_error {
        display: flex;
        align-items: center;
        width: 100%;
        color: var(--color-red);
        padding: 10px 0;
        font-size: 13px;
        font-weight: 400;

        > i {
            font-size: 17px;
            margin-right: 5px;
        }
    }
}
