.ListItemEven,
.ListItemOdd {
    display: flex;
    align-items: center;
    justify-content: center;
}

.virtualBox {
    top: 0;
    left: 0;
    width: 100%;
    height: var(--virtualHeight, 40px);
    &:hover {
        cursor: pointer;
    }
}

.body {
    height: var(--bodyHeight, 90vh);
}

.cancelled {
    color: #c1c6c8;
}

.titleSort {
    cursor: pointer;
    --padding-cell: 10px 17px 10px 10px;

    > i {
        position: absolute;
        transition: transform 0.3s;
        font-size: 20px;
        transform: translateY(-1px);

        &.ascending {
            transform: rotate(180deg) translateY(2px);
        }
    }
}
