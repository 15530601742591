@import "../../../../stylesheets/utilities";

.status_card {
    width: 100%;
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 8px rgba(0, 74, 143, 0.1);
    padding: 13px 60px 13px 18px;
    margin: 0 10px;
    position: relative;
    border: 1px solid var(--card-color);
    background-color: var(--card-bg);

    &::after {
        content: var(--card-icon);
        font-family: "ats-icons" !important;
        font-size: var(--cardstatus-icon, 45px);
        position: absolute;
        top: 15px;
        right: 15px;
        color: var(--card-color);
    }

    &_info {
        display: flex;
        flex-direction: var(--cardstatus-dir, column-reverse);
        align-items: var(--cardstatus-align, flex-start);
        text-transform: uppercase;
        font-size: 16px;
        font-weight: font-weight(bold);

        > span {
            font-size: 32px;
            margin-top: var(--cardstatus-mt, 10px);
            margin-right: 10px;
            color: var(--card-color);
        }
    }

    &_link {
        width: max-content;
        font-size: 14px;
        color: #000;
        text-decoration: none;
        display: var(--cardstatus-show, block);

        &:visited {
            color: #000;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &.Pending {
        --card-color: var(--color-orange);
        --card-bg: var(--color-light-orange);
        --card-icon: "\e930";
    }

    &.Approved {
        --card-color: var(--color-green);
        --card-bg: var(--color-light-green);
        --card-icon: "\e924";
    }

    &.Rejected {
        --card-color: var(--color-red);
        --card-bg: var(--color-light-red);
        --card-icon: "\e923";
    }
}
