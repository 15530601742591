::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background-color: #d9e4ee;
}

::-webkit-scrollbar-thumb {
    background-color: #003c74;
    border-radius: 5px;
}

body {
    min-height: 100vh;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $main-font;
    background-color: var(--color-lighter-sky-blue);

    &.no-overflow {
        overflow: hidden;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
input,
textarea,
button {
    font-family: $main-font;
}

p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}

:focus:not(:focus-visible) {
    outline: none;
}

button,
[type="submit"],
.btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

i {
    color: var(--icon-color);
}

///FOR EXPLODE
///
.grid_row {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    --msg-error-top: 10px;

    > * ~ * {
        margin-left: 15px !important;
    }

    > div {
        min-width: 150px;
        max-width: 300px;
    }

    + div {
        margin-top: 20px;
    }
}

.flex_row {
    display: flex;
    flex-flow: row nowrap;

    > * ~ * {
        margin-left: 15px !important;
    }
}

.layout {
    &_full {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;

        &_head {
            width: 100%;
            padding: 20px 60px;
            position: relative;
            z-index: 100;
        }

        &_body {
            width: 100%;
            height: 100%;
            background-color: #fff;
            overflow: auto;
            position: relative;
            z-index: 50;
        }
    }
}

.grid-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;

    &_head {
        width: 100%;
        padding: 20px 60px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        &_area {
            padding: 10px 0;
            display: flex;
            flex-flow: row nowrap;
        }
    }

    &_body {
        width: 100%;
        height: 100%;
        overflow: auto;
    }
}

.token {
    justify-content: center;
    color: var(--card-color);

    &_options {
        width: 100%;
        display: flex;
        align-items: center;

        > span {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
        }

        > i {
            font-size: 20px;
            margin-left: 5px;
        }
    }

    &_code {
        width: 100%;
        font-size: 26px;
        font-weight: 700;
        margin-top: 10px;
    }
}

.widget {
    background-color: var(--card-bg);
    color: var(--card-color);
    border: solid 1px var(--card-color);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;

    &_title {
        font-size: 26px;
        font-weight: 700;
    }

    &_text {
        font-size: 16px;
        font-weight: 500;
    }
}

.whitebox {
    background-color: #fff;
    border: solid 1px #d2e0e9;
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 8px rgba(0, 74, 143, 0.1);
    padding: 20px;

    &.hasScroll {
        height: 100%;
        overflow: auto;
    }

    &.has_heading {
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    &.centered {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    &.noPadding {
        padding: 0;
    }

    &_area {
        margin-bottom: 30px;
    }

    &_heading {
        width: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        > i {
            font-size: 35px;
            color: var(--color-green);
            margin-right: 10px;
        }

        &_title {
            font-size: 26px;
            font-weight: 500;
        }

        &_subtitle {
            font-size: 16px;
            font-weight: font-weight(bold);
            text-transform: uppercase;
            color: var(--color-blue);
            padding: 10px 0;
        }

        > a {
            margin-left: auto;
            font-size: 16px;
            font-weight: 500;
            color: var(--color-blue);
            border-radius: var(--border-radius);
            padding: 3px 5px;
            transition: background-color 0.3s;

            &:hover {
                background-color: var(--color-lighter-sky-blue);
            }
        }
    }

    &_content {
        width: 100%;
        height: 100%;
        overflow: hidden auto;

        p {
            overflow-wrap: break-word;
        }
    }

    &_list {
        --label-mb: 3px;
        margin: 0;
        padding: 10px 0;

        li {
            font-size: 14px;
            margin: 10px 0px;
        }
    }

    .inColumns {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
        gap: 0 20px;
    }
}

///TABLES
.ats_table {
    width: 100%;
    background-color: #fff;

    thead {
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 5;
        background-color: #fff;
        overflow: hidden;
        //box-shadow: 0 0 0 1px #c1c6c8;

        tr {
            box-shadow: -1px 1px 1px #c1c6c8;
        }
    }

    tbody {
        width: 100%;

        tr {
            border-bottom: solid thin #c1c6c8;
            transition: background-color 0.3s;

            &:hover {
                background-color: #f2f6f9;
                cursor: pointer;
            }
        }
    }

    th,
    td {
        width: auto;
        text-align: left;
    }

    th {
        font-size: 14px;
        font-weight: 700;
        padding: var(--padding-cell, 10px);
        color: #6692bc;
        text-transform: uppercase;
    }

    td {
        font-size: 14px;
        padding: var(--padding-cell, 10px);
    }

    &_alert {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &_box {
            width: 100%;
            max-width: 420px;
            padding: 30px;
            background-color: var(--color-sky-blue);
            border: solid 1px var(--color-blue);
            border-radius: var(--border-radius);
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 4px 8px rgba(0, 74, 143, 0.1);

            > button {
                margin-bottom: -50px;
            }
        }

        &_title {
            font-size: 24px;
            font-weight: 700;
            color: var(--color-blue);
            text-transform: uppercase;
        }

        &_text {
            font-size: 16px;
            font-weight: 400;
            color: var(--color-blue);
            text-align: center;
            margin: 10px 0 25px;
        }
    }
}

///F0RMS

.form {
    &_title {
        width: 100%;
        font-size: 21px;
        font-weight: 400;
    }

    &_fieldset {
        --items-pady: 10px 0;
        width: 100%;
        display: flex;
        flex-flow: var(--fieldset-dir, column) nowrap;
        justify-content: flex-start;
        position: relative;
        padding: 15px;

        &.nopad {
            padding: 0;
        }

        &.row {
            --fieldset-dir: row;
            --items-pad: 20px;
        }

        &.colx2 {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        }

        &.maxWidth {
            max-width: 450px;
            margin: 0 auto;
        }

        .group {
            width: 100%;
            display: grid;
            gap: 15px;
            padding: 10px 0;

            &.c1x1 {
                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            }

            &.btn-end {
                margin-top: 10px;
                justify-content: end;
            }
        }
    }

    &_item {
        flex: 1 1 var(--items-basis, auto);
        position: relative;
        padding: var(--items-pady, 0);

        > i {
            position: absolute;
            top: 6px;
            left: 10px;
            font-size: 20px;
        }

        &.has-icon {
            --item-padding: 7px 10px 7px 35px;
        }

        &.max-width {
            max-width: 300px;
        }

        + .form_item {
            margin-left: var(--items-pad, 0);
        }

        &.danger {
            --display-alert: inline-block;
            --outline-alert: solid 1px var(--card-color);

            &::before {
                pointer-events: all;
                display: var(--display-alert, none);
                content: "!";
                color: #fff;
                background-color: var(--card-color, none);
                padding: 1px 5px;
                border-radius: 50%;
                font-size: 10px;
                font-weight: 400;
                margin-right: 5px;
                position: absolute;
                right: -5px;
                top: var(--error-icon-top, 20px);
            }

            &:hover,
            &:focus {
                --show-error: block;
            }
        }

        &.picker {
            position: relative;
            margin-bottom: 15px;
            --error-icon-top: -10px;
            --msg-error-top: 2px;
            --spectrum-textfield-padding-x: 5px !important;

            > :nth-child(1) {
                display: flex;

                > div {
                    outline: solid 2px var(--card-color, none);
                    border-radius: 4px;
                }

                > div + div {
                    margin-left: 15px;
                }
            }
        }

        .capital {
            text-transform: capitalize;
        }
    }

    &_label,
    label {
        width: 100%;
        min-height: 16px;
        font-size: 14px;
        font-weight: font-weight(bold);
        text-transform: uppercase;
        color: var(--color-light-blue);
        margin-bottom: var(--label-mb, 5px);
        margin-top: 10px;
    }

    input,
    button,
    select {
        outline: solid 2px var(--card-color, none);
    }

    .showError {
        --show-error: block;
    }
}

input,
textarea,
select {
    width: 100%;
    height: 35px;
    padding: var(--item-padding, 7px 10px);
    background-color: #fff;
    border: solid 1px #003c74;
    border-radius: 4px;
}

textarea {
    min-height: 60px;
}

select {
    max-height: 35px;
}

[type="checkbox"],
[type="radio"] {
    accent-color: #003c74;
}

[type="checkbox"] {
    width: min-content;
    height: 20px;
    cursor: pointer;
}

.btn_group {
    width: max-content;
    margin-left: auto;

    .btn {
        --btn-ml: 0;
        --btn-radius: 4px 0 0 4px;

        + .btn {
            --btn-radius: 0 4px 4px 0;
            border-left: none;
        }
    }
}

//BTN SWITCH
.btn_switch {
    display: block;
    width: 70px;
    max-width: 70px !important;
    height: 35px;
    overflow: hidden;
    position: relative;

    input:checked + span {
        --switch-x: 35px;
        --switch-color1: var(--color-light-blue);
        --switch-color2: #fff;
    }

    > span {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #fff;
        border: solid 1px var(--color-light-blue);
        border-radius: var(--border-radius);
        cursor: pointer;

        > i {
            font-size: 20px;
            color: var(--switch-color1, #fff);
            padding: 7px;
            z-index: 5;

            &:nth-child(2) {
                color: var(--switch-color2, var(--color-light-blue));
            }
        }

        &::after {
            content: "";
            width: 50%;
            height: 100%;
            border-radius: var(--border-radius);
            background-color: var(--color-light-blue);
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            transform: translateX(var(--switch-x, 0));
            transition: transform 0.3s;
        }
    }
}

///STATES COLOR
.cool {
    --card-bg: #d9e4ee;
    --card-border-color: #004a8f;
    --card-color: #004a8f;
    --badge-bg: #d9e4ee;
    --badge-color: #004a8f;
    --icon-color: #004a8f;
}

.warning {
    --card-bg: #ffe5c9;
    --card-border-color: #ff9a28;
    --card-color: #ff9a28;
    --badge-bg: #ffe5c9;
    --badge-color: #ff9a28;
    --icon-color: #ff9a28;
}

.danger {
    --card-bg: #f7d8d5;
    --card-border-color: #e06658;
    --card-color: #e06658;
    --badge-bg: #f7d8d5;
    --badge-color: #e06658;
    --icon-color: #e06658;
}

.success {
    --card-bg: #daf6e9;
    --card-border-color: #039b54;
    --card-color: #039b54;
    --badge-color: #039b54;
    --badge-bg: #daf6e9;
    --icon-color: #039b54;
}

//HELPERS
.flex {
    display: flex;
}

.row_inputs {
    display: grid;
}

.line {
    display: flex;
    flex-flow: row nowrap;
    padding: 20px 40px;
}

.hasTooltip {
    position: relative;
    // background-color: var(--card-bg, #fff);
    height: 100%;

    &.withHover {
        --tooltip-opacity: 0;
        --tooltip-pevents: none;

        &:hover {
            --tooltip-opacity: 1;
            --tooltip-pevents: all;
        }
    }
}

.print-hidden {
    @media print {
        display: none !important;
    }
}

.print-block {
    @media print {
        display: block !important;
        z-index: 1;
    }
}

[class^="spectrum-"],
[class*=" spectrum-"] {
    position: relative;
    z-index: 400;
}
