$main-font: "Noto Sans", -apple-system, BlinkMacSystemFont, Tahoma, Geneva, Verdana, sans-serif;

$fonts: (
    "Noto Sans": (
        black: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-Black.ttf",
            weight: 900,
        ),
        black-italic: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-BlackItalic.ttf",
            weight: 700,
        ),
        bold: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-Bold.ttf",
            weight: 700,
        ),
        bold-italic: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-BoldItalic.ttf",
            weight: 700,
            style: italic,
        ),
        extra-bold: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-ExtraBold.ttf",
            weight: 800,
        ),
        extra-bold-italic: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-ExtraBoldItalic.ttf",
            weight: 800,
            style: italic,
        ),
        extra-light: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-ExtraLight.ttf",
            weight: 200,
        ),
        extra-light-italic: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-ExtraLightItalic.ttf",
            weight: 200,
            style: italic,
        ),
        italic: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-Italic.ttf",
            weight: 400,
            style: italic,
        ),
        light: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-Light.ttf",
            weight: 300,
        ),
        light-italic: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-LightItalic.ttf",
            weight: 300,
            style: italic,
        ),
        medium: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-Medium.ttf",
            weight: 500,
        ),
        medium-italic: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-MediumItalic.ttf",
            weight: 500,
            style: italic,
        ),
        regular: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-Regular.ttf",
            weight: 400,
        ),
        semi-bold: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-SemiBold.ttf",
            weight: 600,
        ),
        semi-bold-italic: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-SemiBoldItalic.ttf",
            weight: 600,
            style: italic,
        ),
        thin: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-Thin.ttf",
            weight: 100,
        ),
        thin-italic: (
            ttf: "../assets/fonts/Noto_Sans/NotoSans-ThinItalic.ttf",
            weight: 100,
            style: italic,
        ),
    )
);

@each $font-family, $weights in $fonts {
    @each $font-weight, $props in $weights {
        $style: normal;

        @if (map-has-key($props, style)) {
            $style: map-get($props, style);
        }

        @font-face {
            font-family: $font-family;
            src: url(map-get($props, ttf)) format("truetype");
            font-weight: map-get($props, weight);
            font-style: $style;
            font-display: swap;
        }
    }
}




