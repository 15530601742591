.layout {
    width: 100%;
    height: 100%;
    padding: 20px 60px;
    display: flex;
    gap: 20px;
    overflow: hidden;

    > div {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &_aside {
        flex: 1 1 100%;
        max-width: 400px;

        >div:nth-child(2) {
            min-height: 100px;
            max-height: 300px;
        }

        >div:nth-child(3) {
            flex: 1 1 100%;
        }
    }

    &_main {
        width: 100%;

        > div {
            flex: 1 1 auto;
            max-height: 70%;
        }
    }

    .iconEditComment {
        font-size: 15px;
    }
}
