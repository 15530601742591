.layout {
    width: 100%;
    height: 100%;
    padding: 20px 60px;
    display: grid;
    grid-template-columns: 3fr 9fr;
    gap: 15px;

    &_column {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 15px;
        overflow: hidden;
    }

    .iconEditComment {
        font-size: 15px;
    }
}
//TO EXPORT CARDSTATUS COMPONENT
.card_status {
    padding: 10px 20px;
    background-color: var(--color-sky-blue);
    border: solid 2px var(--color-blue);
    border-radius: var(--border-radius);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    > i {
        font-size: 50px;
        color: var(--icon-color);
        margin-right: 10px;
    }

    > a {
        width: max-content;
        white-space: nowrap;
        color: var(--color-blue);
    }

    &_info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &_title {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-blue);
        text-transform: uppercase;
    }

    &_value {
        font-size: 28px;
        font-weight: 700;
        color: var(--color-blue);
        text-transform: uppercase;
    }
}
