.layout {
    width: 100%;
    height: 100%;
    padding: 20px 60px;
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 20px;

    &_area {
        height: 100%;
    }
}
