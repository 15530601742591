.alertBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &_container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_icon {
        font-size: 45px;
        position: absolute;
        z-index: 100;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--color-red);
        border-radius: 100%;
        color: #fff;
        padding: 7px;
    }

    &_content {
        position: relative;
        width: 100%;
        max-width: 420px;
        padding: 30px;
        background-color: var(--color-light-red);
        border: solid 1px var(--color-red);
        border-radius: var(--border-radius);
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 4px 8px rgba(0, 74, 143, 0.1);

        > button {
            margin-bottom: -50px;
        }
    }

    &_title {
        font-size: 24px;
        font-weight: 700;
        color: #000;
        text-transform: uppercase;
    }

    &_text {
        font-size: 16px;
        font-weight: 400;
        color: #000;
        text-align: center;
        margin: 10px 0 25px;
    }
}
