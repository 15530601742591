.tooltip {
    position: absolute;
    top: var(--tooltip-t, initial);
    left: var(--tooltip-l, initial);
    bottom: var(--tooltip-b, initial);
    right: var(--tooltip-r, initial);
    width: max-content;
    border: solid 1px var(--color-blue);
    border-radius: var(--border-radius);
    background-color: var(--color-sky-blue);
    color: var(--color-blue);
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 74, 143, 0.1);
    transition: opacity 0.3s;
    opacity: var(--tooltip-opacity, 1);
    pointer-events: var(--tooltip-pevents, all);

    &::after {
        content: "";
        width: 10px;
        height: 5px;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        background-color: var(--color-blue);
        position: absolute;
        top: var(--tooltip-arrow-t, initial);
        left: var(--tooltip-arrow-l, initial);
        bottom: var(--tooltip-arrow-b, initial);
        right: var(--tooltip-arrow-r, initial);
    }

    &_heading {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;

        > i {
            font-size: 25px;
            margin-right: 10px;
        }
    }

    &_content {
        width: 100%;
    }
}

.right {
    text-align: right;
}
.center {
    text-align: center;
}
.left {
    text-align: left;
}
