.layout {
    width: 100%;
    height: 100%;
    padding: 40px 60px;
    display: flex;
    gap: 20px;
    overflow: hidden;
    
    &_aside {
        flex: 1 1 100%;
        max-width: 450px;
    }
    
    &_content {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr;
        gap: 20px;

        &_widgets {
            display: flex;
            gap: 20px;

            > article {
                flex: 1 1 auto;
            }
        }

        > section {
            flex: 1 1 100%;
        }

    }
}
