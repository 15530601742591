.layout {
    width: 100%;
    height: 100%;
    padding: 20px 60px;
    display: flex;
    grid-template-columns: 1fr minmax(300px, 400px);
    gap: 20px;

    &_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        &_widgets {
            display: flex;
            gap: 15px;

            > article {
                flex: 1 1 auto;
            }
        }
    }
    &_aside {
        flex: 1 1 auto;
        width: 100%;
        min-width: 300px;
        max-width: 400px;
    }
}
