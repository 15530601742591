@import "../../../stylesheets/utilities";

.ats_aside {
    width: var(--aside-minmax-width, 160px);
    min-width: var(--aside-minmax-width, 160px);
    height: 100%;
    background-color: var(--back-aside);
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    transition: max-width 0.3s, min-width 0.3s;

    &.collapsed {
        --aside-minmax-width: 50px;
        --span-menu-item: 0;
        --pad-aside-min: 5px;
    }

    &_header {
        width: 100%;
        padding: 30px var(--pad-aside-min, 25px);
        transition: padding 0.3s;

        &_identity {
            width: 100%;

            > img {
                width: 100%;
                max-width: 40px;
            }
        }
    }

    &_nav {
        width: 100%;
        transition: padding 0.3s;
        padding: 13px var(--pad-aside-min, 13px);
    }

    &_menu {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
            width: 100%;

            &.showOptions {
                --user-opts-h: 130px;
                --user-opts-r: 180deg;
                --user-opts-y: 0;
            }
        }

        &_item {
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            text-decoration: none;
            color: #fff;
            background-color: transparent;
            font-size: 14px;
            font-weight: font-weight(regular);
            border-radius: 4px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            transition: background-color 0.3s;

            &.active {
                background-color: rgba($color: #fff, $alpha: 0.3);
                font-weight: font-weight(bold);
            }

            &:hover {
                background-color: rgba($color: #fff, $alpha: 0.1);
            }

            > i {
                font-size: 20px;
            }
            > span {
                width: var(--span-menu-item, 100%);
                margin-left: 10px;
                opacity: var(--span-menu-item, 1);
                transition: width 0.3s, opacity 0.2s;
                text-align: left;
                overflow: hidden;
            }

            &.collapse {
                width: auto;
                border: none;
            }
        }
    }

    &_showbtn {
        transform: rotate(var(--user-opts-r, 0)) translateY(var(--user-opts-y, 3px));
        transition: transform 0.3s;
        opacity: var(--span-menu-item, 1);
    }

    &_submenu {
        width: 100%;
        height: var(--user-opts-h, 0);
        margin: 0;
        padding: 0;
        overflow: hidden;
        transition: height 0.3s;

        li:nth-last-child(1) span {
            white-space: nowrap;
        }
    }

    &_footer {
        width: 100%;
        margin-top: auto;
        padding: 30px var(--pad-aside-min, 13px);
        transition: padding 0.3s;

        &_menu {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }
}
