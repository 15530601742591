@import "../../../stylesheets/utilities";

.info {
    padding: 20px 25px;
    background-color: var(--card-bg);
    border: solid 1px var(--card-border-color);
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 8px rgba(0, 74, 143, 0.1);
    display: flex;
    align-items: center;

    > i {
        font-size: 25px;
        margin-right: 20px;
        color: var(--icon-color);
    }

    &_text {
        font-size: 16px;
        font-weight: font-weight(regular);
        color: var(--card-color);
    }

    &_title {
        font-size: 16px;
        font-weight: font-weight(bold);
        color: var(--card-color);
    }

    .wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
