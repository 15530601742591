.layout {
    height: 100%;
    display: flex;
    gap: 15px;
    padding: 20px 60px;

    p {
        white-space: pre-wrap;
    }

    > div {
        flex: 1 1 100%;
        height: 100%;
    }

    &_aside {
        min-width: 300px;
        max-width: 400px;
        gap: 15px;
        display: flex;
        flex-flow: column nowrap;

        &_top {
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 15px;

            &.fullProgressBar {
                grid-template-columns: 100%;
            }
        }
    }

    &_content {
        height: 100%;
        white-space: pre-wrap;
    }

    &_list {
        height: 100%;
        white-space: pre-wrap;
    }

    .reportButtonWrapper {
        padding: 0;

        > button {
            height: 100%;
            width: 100%;
        }
    }

    .iconEditComment {
        font-size: 15px;
    }
}

:global .whitebox {
    .whitebox_content {
        .add_comment_btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
        }
    }
    > li {
        list-style-type: none;
    }
}

.rescheduleComment {
    margin-top: 5px;
}
